// import { REALMS_LIST } from "../config/config";

export const fetchDataFromAPIRealms = async () => {
    try {
        // const apiUrl = REALMS_LIST || 'https://stg.stattus4.com/api/realmsList';
        const apiUrl = 'https://stg.stattus4.com/api/realmsList';
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error(`Erro na API: ${response.statusText}`);
        }
        const data = await response.json();
        localStorage.setItem('realms-list-stt4', data)
        return data;
    } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
        throw error;
    }
};