import React, { useEffect, useState } from 'react';
import { keycloak } from '../../keycloak';
import { useAuth } from '../../hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';

import {
    LoadingOverlay,
    LoadingSpinnerWrapper,
    LoadingMessageWrapper,
    LoadingMessage
} from './styles'
import useHeaderStore from '../../store/useHeaderStore';

const Intercept = () => {
    const { login } = useAuth();
    const { clientId } = useParams();
    const { showHeader, hideHeader } = useHeaderStore();
    const navigate = useNavigate();
    const [password, setPassword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (!keycloak.authenticated) {
                const queryString = window.location.search;
                const params = new URLSearchParams(queryString);
                const module = params.get("module");
   
                if (module) {
                    keycloak.login({ redirectUri: window.location.origin + '/intercept/' + clientId + `?module=${module}` });
                } else {
                    keycloak.login({ redirectUri: window.location.origin + '/intercept/' + clientId });
                }

            } else {
                setFlag(true);
            }
        }, 2500)
    }, []);

    useEffect(() => {
        if (keycloak) {
            if (keycloak && keycloak.authenticated) {
                localStorage.setItem('isKcAda', true);
                localStorage.setItem('last_client', clientId);
                handleLogin();
            }
        }
    }, [flag])


    const handleLogin = async (e) => {
        setLoading(true);
        
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const module = params.get("module");
      
        const response = await login(keycloak.tokenParsed.email, password, keycloak.token);
        if (login) {
            if (response) {
                if (!module) {
                    showHeader();
                    navigate(`/home`);
                } else {
                    hideHeader();
                    navigate(`/${module}`);
                }
            } else {
                setLoading(false);
            }
        } else {
            alert('Função de autenticação não encontrada.');
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && <LoadingOverlay>
                <LoadingSpinnerWrapper />
                <LoadingMessageWrapper>
                </LoadingMessageWrapper>
            </LoadingOverlay>
            }
        </div>
    )
};

export default Intercept;